<template>
  <div>
    <div class="card height-auto v-application--is-ltr text-left">
      <div class="card-body">
        <v-data-table
          :options.sync="options"
          :loading="loading"
          loading-text="Loading... Please wait"
          v-model="selected"
          :headers="headers"
          :items="items"
          item-key="id"
          :show-select="false"
          class="elevation-1 level1 templates"
          text="left"
          hide-default-footer
        >
          <template v-slot:top>
            <v-row class="headerTable-div">
              <v-col md="4" sm="12">
                <h3 class="custom-header main-color">
                  {{ $t("Discussion Room") }}
                </h3>
              </v-col>
              <v-col md="4" sm="12">
                <label class="search" for="inpt_search">
                  <input
                    id="inpt_search"
                    class="input_search"
                    @focus="inputFocus"
                    @blur="inputBlur"
                    type="text"
                    @keyup.enter="getAllData(page)"
                    v-model.trim="search"
                  />

                  <v-icon
                    title="Search..."
                    @click="getAllData(page)"
                    class="search-icon"
                    >search</v-icon
                  >
                </label>

                <span :title="$t('Filter')" v-if="!student">
                  <v-menu
                    v-model="filterMenu"
                    offset-y
                    :close-on-content-click="false"
                    :nudge-width="300"
                    offset-x
                  >
                    <template v-slot:activator="{ on }">
                      <i
                        v-on="on"
                        class="fas fa-filter main-color fa-lg"
                        :title="$t('Filter')"
                      ></i>
                    </template>

                    <v-list>
                      <v-list-item class="select">
                        <v-autocomplete
                          v-model="filter.classes"
                          :label="$t('Class')"
                          :items="classes"
                          @change="hideLabel = true"
                          item-text="name"
                          item-value="id"
                          multiple
                          solo
                        >
                          <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index < 2">
                              <span>{{ item.name }}</span>
                            </v-chip>
                            <span v-if="index == 2" class="grey--text caption"
                              >(+{{ filter.classes.length - 2 }}
                              {{ $t("others") }})</span
                            >
                          </template>
                        </v-autocomplete>
                      </v-list-item>

                      <v-card-actions> </v-card-actions>
                    </v-list>
                  </v-menu>
                </span>
              </v-col>

              <v-col md="3" sm="12" class="text-right">
                <!-- ==================================================filters================================================== -->
                <v-icon
                  large
                  v-if="permissions.add"
                  @click="OpenDialogAddEdit"
                  class="main-color"
                  :title="$t('Add New')"
                  >add_circle</v-icon
                >

                <!-- ================== Activity logs ================== -->
                <v-btn
                  icon
                  to="/systemlogging/discussion_room"
                  v-if="activitylogPermission"
                  target="_blank"
                >
                  <v-icon class="main-color" large :title="$t('System Log')"
                    >mdi-archive-clock</v-icon
                  >
                </v-btn>
              </v-col>
            </v-row>
          </template>
          <!-- name -->
          <template v-slot:item.name="{ item }">
            <router-link target="_blank" :to="'/discussionboard/' + item.id">{{
              item.name
            }}</router-link>
          </template>

          <!-- Classes -->
          <template v-slot:item.classes="{ item }">
            <v-tooltip bottom max-width="2800px">
              <template v-slot:activator="{ on }">
                <p dark v-on="on" v-if="item.classes">
                  {{ item.classes.slice(0, 30) }}
                </p>
              </template>
              <span>{{ item.classes }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.actions="{ item }">
            <!-- edit -->

            <v-icon
              class="main-color"
              @click="edit(item)"
              v-if="permissions.edit && item.can_edit_delete"
              :id="item.id"
              :title="$t('Edit')"
              >edit</v-icon
            >
            <!-- delete -->
            <v-icon
              v-if="permissions.delete && item.can_edit_delete"
              class="main-color"
              :id="item.id"
              @click="openDialog(item)"
              :title="$t('Delete')"
              >delete</v-icon
            >
          </template>
        </v-data-table>
        <!-- ======================================== Pagination ======================================  -->
        <div class="text-center pt-2">
          <v-pagination
            v-if="length > 0 && totalItems > 0"
            v-model="page"
            :length="length"
            :circle="circle"
            :page="page"
            :total-visible="totalVisible"
            next-icon="mdi-menu-right"
            prev-icon="mdi-menu-left"
          ></v-pagination>
          <v-snackbar
            v-model="snack"
            :timeout="snackTime"
            :color="snackColor"
            :right="true"
            :top="true"
          >
            <!-- {{ snackText }} -->

            <span class="white--text">{{ snackText }}</span>

            <v-btn text @click="snack = false">Close</v-btn>
          </v-snackbar>
        </div>
      </div>
    </div>

    <addEditDiscussionRoom
      :editedItem="editedItem"
      :AddEditdialog="AddEditdialog"
      :formTitle="formTitle + ' Discussion Room'"
      v-on:childToParent="onChildClick"
      :editedIndex="editedIndex"
      :endPoints="this.apiEndPoints"
    ></addEditDiscussionRoom>

    <v-dialog
      v-model="dialogReset"
      persistent
      max-width="320"
      class="dialog_confirm"
    >
      <v-card>
        <v-card-title class="headline">
          {{ $t("Confirmation Message") }}
        </v-card-title>

        <v-card-text>
          {{ $t("Are you sure you want to Delete this Room?") }}</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            class="modal-btn-cancel noBtn"
            @click="dialogReset = false"
            id="noBtn"
            ref="always_focus"
            >{{ $t("No") }}</v-btn
          >
          <v-btn
            id="yesBtn"
            @click="deleteItem(editedItem)"
            class="modal-btn-save yesBtn"
            >{{ $t("Yes") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import addEditDiscussionRoom from "../../components/modals/addEditDiscussionRoom";
import axios from "axios";
import ACL from "../../acl";
export default {
  name: "discussionRooms",
  components: { addEditDiscussionRoom },
  data() {
    return {
      activitylogPermission: ACL.checkPermission("activitylog"),

      options: {
        itemsPerPage: 15,
      },
      snack: "",
      student: ACL.Role("Student"),
      types: [],
      dialogReset: false,
      snackTime: 3000,
      snackColor: "",
      snackText: "",
      filterMenu: false,
      loading: true,
      formTitle: "",
      selected: [],
      headers: [
        {
          text: this.$i18n.t("Name"),
          value: "name",
        },
        // {
        //   text: this.$i18n.t("Arabic Name"),
        //   value: "ar.name"
        // },

        {
          text: this.$i18n.t("Classes"),
          value: "classes",
        },

        {
          text: this.$i18n.t("#Discussions"),
          value: "discussions",
        },
        {
          text: this.$i18n.t("Actions"),
          value: "actions",
          sortable: false,
          width: "12%",
        },
      ],
      title: "Discussion Room",
      apiEndPoints: {
        list: "discussionboard",
        create: "discussionboard/store",
        edit: "discussionboard/update",
        delete: "discussionboard/destroy",
      },
      editedItem: {
        id: "",
        name: "",
        en: {
          name: "",
        },
        ar: {
          name: "",
        },
        about: "",
        valid_unitll: "",
        class_id: [],
        need_approve: false,
        replay_need_approve: false,
        date: "",
        modirators: [],
      },
      permissions: {
        list: ACL.checkPermission("list-discussion-rooms"),
        add: ACL.checkPermission("add-discussion-rooms"),
        edit: ACL.checkPermission("edit-discussion-rooms"),
        delete: ACL.checkPermission("delete-discussion-rooms"),
      },

      items: [],
      filter: {
        classes: [],
      },
      search: "",

      classes: [],

      circle: true,
      page: 1,
      length: "",
      totalVisible: "",
      totalItems: "",
      AddEditdialog: false,
      editedIndex: -1,
      confirmDialoag: false,
    };
  },
  watch: {
    "$route.query": {
      handler() {
        if (this.$route.query.page !== undefined) {
          this.page = Number(this.$route.query.page);
          this.getAllData(this.$route.query.page);
        } else {
          this.getAllData(1);
        }
      },
      deep: true,
    },
    page: function () {
      this.$router.push(
        {
          path: "/discussionrooms?page=" + this.page,
        },
        () => {}
      );
    },

    search: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
    filter: {
      handler() {
        this.page = 1;
        this.getAllData(this.page);
      },
      deep: true,
    },
  },
  methods: {
    onChildClick(value) {
      this.AddEditdialog = value;
      this.getAllData(this.page);
    },
    edit(item) {
      this.editedIndex = this.items.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.formTitle = "Edit";
      this.AddEditdialog = true;
    },

    openDialog(item) {
      this.dialogReset = true;
      this.editedItem = item;
    },

    deleteItem(item) {
      // console.log(item);
      axios
        .get(this.getApiUrl + "/" + this.apiEndPoints.delete + "/" + item.id, {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.dialogReset = false;
          console.log(response.data);
          this.snack = true;
          this.snackColor = "green";
          this.snackText = response.data.status.message;
          this.getAllData();
        });
    },
    OpenDialogAddEdit() {
      this.editedIndex = -1;
      // this.table.editedItem = {};
      if (this.editedItem.en) {
        Object.keys(this.editedItem.en).forEach(
          (k) => (this.editedItem.en[k] = "")
        );
        Object.keys(this.editedItem).forEach((k) => {
          if (k != "en" && k != "ar") this.editedItem[k] = "";
        });
      } else {
        Object.keys(this.editedItem).forEach((k) => (this.editedItem[k] = ""));
      }
      if (this.editedItem.ar) {
        Object.keys(this.editedItem.ar).forEach(
          (k) => (this.editedItem.ar[k] = "")
        );
      }
      this.formTitle = "Add";
      this.AddEditdialog = true;
    },

    inputFocus() {
      $("#inpt_search").parent("label").addClass("active");
      $(".input_search").parent("label").addClass("active");
    },
    inputBlur() {
      if (this.search == "") {
        $("#inpt_search").parent("label").removeClass("active");
        $(".input_search").parent("label").removeClass("active");
      }
    },

    getAllData(page = null) {
      if (page == null) {
        page = this.page;
      }
      axios
        .get(
          this.getApiUrl +
            "/" +
            this.apiEndPoints.list +
            "?search=" +
            this.search +
            "&page=" +
            page,
          {
            params: {
              filter: this.filter,
            },
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          this.setPaginationParameters(response);
          this.totalItems = response.data.data.total;
          this.items = [];
          this.items = response.data.data.data;
          this.loading = false;
        });
    },

    setPaginationParameters(response) {
      this.length = response.data.data.last_page;
      if (this.length > 5) this.totalVisible = 5;
    },

    getClasses() {
      axios
        .get(this.getApiUrl + "/discussionboard/getClasses", {
          headers: {
            Authorization: "Bearer " + localStorage.token,
          },
        })
        .then((response) => {
          this.classes = response.data.data;
          //   this.subjects = response.data.data.subjects;
        });
    },
  },
  mounted() {
    this.getAllData(this.page);
    this.getClasses();
  },
};
</script>
<style lang="scss" scoped>
@import "../../styles/_variables.scss";
@import "../../styles/_modals.scss";

#wrapper {
  transition: all 0.4s ease 0s;
}

.main-color {
  color: $main-color;
}
.headerTable-div {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #fff;

  .custom-header {
    margin-bottom: 0;
  }
}

.center {
  margin-left: 30px !important;
}

.select {
  margin-top: 20px !important;
}
</style>
